<template>
<div class="relative main_box">
    <Slider :ScroY="ScroY" />
    <Photos :ScroY="ScroY" />
    <Icons :ScroY="ScroY" />
</div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import Photos from '@/components/Photos.vue'
import Icons from '@/components/Icons.vue'

export default {
    props: {
        ScroY: Number
    },
    components: {
        Slider,
        Photos,
        Icons
    },

}
</script>

<style lang="scss" scoped>
.main_box {
    width: 100%;
    height: 6500px;
}
</style>
