<template>
<div class="absolute">
    <div ref="photo" :class="'fixed photo'+i" v-for="i in 5" :key="i">
        <div>
        <img :src="'../../imgs/photo'+i+'.jpg'" class="photo" />
        <p  class="photo_p absolute fui photo_p1 amber--text text--darken-4 f-14" v-if="i==1">05.26 あ、ココ知ってる</p>
        <p  class="photo_p absolute fui photo_p2 amber--text text--darken-4 f-14" v-if="i==2">06.08 海の底が見えるよ</p>
        <p  class="photo_p absolute fui photo_p3 amber--text text--darken-4 f-14" v-if="i==3">07.16 ドキドキの初体験</p>
        <p  class="photo_p absolute fui photo_p4 amber--text text--darken-4 f-14" v-if="i==4">08.28 み ん な な か ま</p>
        <p  class="photo_p absolute fui photo_p5 amber--text text--darken-4 f-14" v-if="i==5">09.09 思い出ができたよ</p>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
        }
    },
    props: {
        ScroY: Number
    },
    mounted() {
        this.scrolly()
    },
    methods: {
        async scroll01() {
            for (let i = 1; i < 6; i++) {
                if (this.ScroY > 500 * (i + 1) - 300) {
                    this['show'+i] = true
                } else {
                    this['show'+i] = false
                }
            }
        },
        scrolly() {
            this.scroll01()
        }
    },
    watch: {
        ScroY() {
            this.scrolly()
        },
        show1(val) {
            if (val) {
                this.$refs.photo[0].classList.remove("fade-out1")
                this.$refs.photo[0].classList.add("fade-in1")
            } else {
                this.$refs.photo[0].classList.remove("fade-in1")
                this.$refs.photo[0].classList.add("fade-out1")
            }
        },
        show2(val) {
            if (val) {
                this.$refs.photo[1].classList.remove("fade-out2")
                this.$refs.photo[1].classList.add("fade-in2")
            } else {
                this.$refs.photo[1].classList.remove("fade-in2")
                this.$refs.photo[1].classList.add("fade-out2")
            }
        },
        show3(val) {
            if (val) {
                this.$refs.photo[2].classList.remove("fade-out3")
                this.$refs.photo[2].classList.add("fade-in3")
            } else {
                this.$refs.photo[2].classList.remove("fade-in3")
                this.$refs.photo[2].classList.add("fade-out3")
            }
        },
        show4(val) {
            if (val) {
                this.$refs.photo[3].classList.remove("fade-out4")
                this.$refs.photo[3].classList.add("fade-in4")
            } else {
                this.$refs.photo[3].classList.remove("fade-in4")
                this.$refs.photo[3].classList.add("fade-out4")
            }
        },
        show5(val) {
            if (val) {
                this.$refs.photo[4].classList.remove("fade-out5")
                this.$refs.photo[4].classList.add("fade-in5")
            } else {
                this.$refs.photo[4].classList.remove("fade-in5")
                this.$refs.photo[4].classList.add("fade-out5")
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/photos.scss";
</style>
