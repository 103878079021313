<template>
<div class="text-center">
    <div class="relative w-100 h-100vh slider">
        <div :class="'slide'+i+' fixed_x opacity0'" v-for="i in 4" :key="i" ref="img">
            <div>
                <v-img :src="'../../imgs/slide'+i+sub+'.jpg'" />
                <div class="fixed serif f-25 f-22-md f-16-sm f-14-xs slide_p1" v-if="i==1">
                    <div class="text1">時の刻みが 遅いと感じる</div>
                    <div class="relative text2">非日常の空間</div>
                </div>
                <div class="fixed serif f-23  f-16-sm slide_p2" v-if="i==2">
                    身体が そして心が <br class="dis-xs">動かずにはいられない
                </div>
                <div class="fixed serif f-28 f-23-md f-16-sm  slide_p3" v-if="i==3">
                    絵日記のひとコマが <br class="dis-xs">ここから生まれる
                </div>
                <div class="fixed serif f-25 f-22-md  f-14-sm slide_p4" v-if="i==4">
                    <div class="text1">本当は教えたくない……</div>
                    <div class="relative text2">でも やっぱり教えたくなる<br class="dis-xs">秘密の場所</div>
                </div>
            </div>
        </div>
        <div class="w-100 h-100vh fixed t-0 l-0" :class="{'bg-opacity':bgOpacity,'bg-fade':!bgOpacity}"></div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            speed: "40",
            bgOpacity: false,
            sub: "",
        }
    },
    props: {
        ScroY: Number
    },
    mounted() {

        for (let i = 0; i < this.$refs.img.length; i++) {
            this.$refs.img[i].classList.add("slide" + (i + 1) + "_" + this.speed)
        }

        if (window.innerHeight / window.innerWidth<0.76&&window.innerHeight / window.innerWidth>0.65) {
                this.sub = "_2"
        }
       else if ((window.innerHeight / window.innerWidth<2.2&&window.innerHeight / window.innerWidth>1.7)|| window.innerWidth<600) {
                this.sub = "_3"
        }
    },
    watch: {
        ScroY() {
            //this.bgOpacity = this.ScroY == 0 ? false : true
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/slider.scss";
</style>
